import { i18nextConfig, type Locale, type ValuesByLocale } from '~/i18n/config'

type NumberFormatOptionsWithLocale = Intl.NumberFormatOptions & {
  locale?: Locale
}

export const FORMAT_OPTIONS_BY_LOCALE: ValuesByLocale<Intl.NumberFormatOptions> =
  {
    fr: {
      style: 'currency',
      minimumFractionDigits: 0,
      currency: 'EUR'
    },
    en: {
      style: 'currency',
      minimumFractionDigits: 0,
      currency: 'EUR'
    }
  }

export function formatEuros(
  euros: number,
  options?: NumberFormatOptionsWithLocale
) {
  const locale = options?.locale ?? i18nextConfig.fallbackLng
  const defaultOptions = FORMAT_OPTIONS_BY_LOCALE[locale]

  return euros.toLocaleString(locale, {
    ...defaultOptions,
    ...options
  })
}

export function convertCentsToEuros(cents: number) {
  return cents / 100
}

export function formatCentsToEuros(
  cents: number,
  options?: NumberFormatOptionsWithLocale
) {
  const euros = convertCentsToEuros(cents)

  return formatEuros(euros, options)
}
